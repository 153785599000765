import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { getVideoSubscription, checkIfEmailExists } from "../../Subscription/service";
import { getUserSubscription } from "../../../network/service"
import { registerUser } from '../Register/service';
import { login } from '../Login/service';
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { analytics2, authenticate } from "../../../network/service";
import { getUser } from "../../../Redux/UserSlice/UserSlice";
import { getLanguageModal } from '../../../Redux/LanguageModal/languageModalSlice';
import { getAccessToken } from "../../../Redux/AToken/ATokenSlice";
import { getUserDetails } from "../../../Redux/UserDetailsSlice/UserDetailsSlice";
import { getUserSubscriptionData } from "../../../Redux/UserSubscriptionDetails/userSubscriptionSlice";
import LoginModal from "../../../Components/Modals/LoginModal/LoginModal";
import { stripeSession } from "../../Subscription/service";

const Checkout = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const projectInfo = useSelector((state) => state?.projectInfo?.value);
    const accessToken = useSelector((state) => state?.accessToken?.value);
    const dispatch = useDispatch();
    const user = useSelector((state) => state?.user?.value);
    const userData = useSelector((state) => state?.userDetails?.value);
    const userSubscriptionData = useSelector((state) => state?.userSubscription?.value);

    const appInfo = {
        projectDetails: projectInfo,
        accessToken: accessToken,
    };
    const videoId = location?.state?.videoId;
    const showId = location?.state?.showId;
    const subscriptionId = location?.state?.subscriptionId;
    const subscriptionPrice = location?.state?.subscriptionPrice;
    const tvCode = location?.state?.code;

    const [subscriptions, setSubscriptions] = useState([]);
    const [selectedPlan, setSelectedPlan] = useState();
    const [selectedPlanPrice, setSelectedPlanPrice] = useState();
    const [activeDetailsPlan, setActiveDetailsPlan] = useState([]);
    const [showPassword, setShowPassword] = useState(false);
    const [activeScreen, setActiveScreen] = useState('register');
    const [isOkToProceed, setIsOkToProceed] = useState();
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [userResponse, setUserResponse] = useState()
    const [error, setError] = useState({});
    const [showAlreadyExistMessage, setShowAlreadyExistMessage] = useState(false);
    const [maskedPassword, setMaskedPassword] = useState();
    const [isFocused, setIsFocused] = useState(false);
    const [resetPasswordMessage, setResetPasswordMessage] = useState();
    const [emailLoginModal, setEmailLoginModal] = useState(false);

    useEffect(() => {

        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });

        fetchSubscriptionList();
        user && setActiveScreen('login-success');

    }, [])


    useEffect(() => {
        if (password) {
            const mask = '*'?.repeat(password.length);
            setMaskedPassword(mask);
        } else {
            setMaskedPassword();
        }
    }, [password]);

    useEffect(() => {
        if (activeScreen == 'register' || activeScreen == 'login-success') {
            setIsOkToProceed(true)
        } else {
            setIsOkToProceed(false)
        }

    }, [activeScreen])

    useEffect(() => {
        if (showAlreadyExistMessage) {
            setTimeout(() => {
                setShowAlreadyExistMessage(false)
            }, 15 * 1000);
        }
    }, [showAlreadyExistMessage])

    useEffect(() => {
        if (resetPasswordMessage) {
            setTimeout(() => {
                setResetPasswordMessage('')
            }, 5 * 1000);
        }
    }, [resetPasswordMessage])

    useEffect(() => {
        // console.log('userSubscriptionData', userSubscriptionData, 'subscriptionId', subscriptionId);

        if (userSubscriptionData?.data?.length > 0) {
            const haveValidSubscription = subscriptionCheck();
            // console.log('haveValidSubscription', haveValidSubscription);
            if (haveValidSubscription) {
                navigate(-1)
            }
        } else {
            // fail silently
        }

    }, [userSubscriptionData])

    const fetchSubscriptionList = async () => {

        const id = videoId ? videoId : showId ? showId : null;
        const type = videoId ? 'video' : showId ? 'show' : '';

        const response = await getVideoSubscription(appInfo, id, type);

        if (response?.status === 200) {
            setSubscriptions(response?.data?.data);

            if (subscriptionId) {

                await fetchUserSubscriptionDetails()


                setSelectedPlan(subscriptionId)
                setSelectedPlanPrice(subscriptionPrice)
            } else {
                setSelectedPlan(response?.data?.data[0]?.subscription_id)
                setSelectedPlanPrice(response?.data?.data[0]?.price)
            }

        }
    };

    const fetchUserSubscriptionDetails = async () => {
        const userSubResponse = await getUserSubscription(appInfo);
        if (userSubResponse?.status === 200) {
            dispatch(
                getUserSubscriptionData({
                    userSubscription: userSubResponse?.data,
                })
            );
        }
    };

    const linkHandler = (path) => {
        navigate(path);
    }

    const handleEmailValidation = async () => {

        if (activeScreen !== 'register') {
            return false;
        } else {

            const isEmailValid = await validateEmail(email);
            if (isEmailValid) {
                setError({
                    ['email']: '',
                    ['password']: ''
                });
                // register case: check whether email already exist or not.

                email && isEmailAlreadyRegistered()
            } else {
                setError({
                    ['email']: 'Invalid email format!',
                    ['password']: ''
                });
            }
        }

    }

    const validateEmail = async (email) => {
        const regex = /^[^@]+@[^@]+\.[^@]+$/;
        return regex.test(email);
    };

    const validatePassword = async (password) => {

        if (password && password != '') {
            return true;
        } else {
            setError({
                ...error,
                ['password']: 'Password required!'
            })
            return false;
        }
    }

    const isEmailAlreadyRegistered = async () => {
        const response = await checkIfEmailExists(appInfo, { user_email: email });

        if (response?.status == 201) {
            // toast.error(response?.data?.message)
            setShowAlreadyExistMessage(true);
            setActiveScreen('login')
        } else {
            console.log(response?.data?.message)
        }
    }

    const handleLoginSubmit = async () => {

        const isEmailValid = await validateEmail(email);
        const isPasswordValid = await validatePassword(password);

        if (isEmailValid && isPasswordValid) {
            setError({})
            const response = await login({ email, password }, appInfo);
            if (response?.status === 200) {
                updateLocalStorage(response)
                setUserResponse(response)
                setActiveScreen('login-success') // display user details and info like please select a plan and click on the continue button to proceed the payment.
            } else if (response?.status == 202) {
                setResetPasswordMessage(response?.data?.message)
            }
        } else {
            manageErrorMessage(isEmailValid, isPasswordValid);
        }


    }

    const subscriptionCheck = () => {
        const userSub = userSubscriptionData?.data;
        const videoSub = subscriptions;
        return userSub?.some((user) => videoSub?.some((video) => user?.sub_id === video?.subscription_id));
    };

    const handleSubmit = async () => {

        if (activeScreen == 'register') {
            const isEmailValid = await validateEmail(email);
            const isPasswordValid = await validatePassword(password);

            if (isEmailValid && isPasswordValid) {
                const response = await registerUser(appInfo, { email, password });
                if (response?.status == 200) {
                    setUserResponse(response)
                    updateLocalStorage(response)
                    // continue the payment flow here
                    proceedToPayment()
                }

            } else {
                manageErrorMessage(isEmailValid, isPasswordValid);
            }
        } else {
            if (user) {
                proceedToPayment();
            } else {
                switchToAnotherAccount()
                setResetPasswordMessage('Invalid credentials');
            }

        }

    }

    const proceedToPayment = async () => {
        const paymentInfo = {
            subId: selectedPlan,
        };
        try {
            const response = await stripeSession(appInfo, paymentInfo);

            if (response?.status === 200) {
                window.open(response?.data?.url, "_self")
            }
        } catch (err) {
            // toast.error(err?.response?.data?.message, {
            //     position: "bottom-center",
            // });
        }
    }

    const updateLocalStorage = (response) => {

        const userDetails = response ? response?.data?.data[0] : userResponse?.data?.data[0];
        if (userDetails?.user_id) {
            localStorage.setItem("userId", userDetails?.user_id);
            localStorage.setItem("selectedSubId", selectedPlan);
            localStorage.setItem("deviceType", "web");
            localStorage.setItem("selectedAmount", selectedPlanPrice);

            tokenAuthenticate();
            updateDeviceAnalytics(
                userDetails?.user_id,
                appInfo,
                userDetails?.user_email
            );
            dispatch(
                getUser({
                    user: userDetails?.user_id,
                })
            );

            fetchSubscriptionList();

        } else {
            console.log('user details not found');

        }
    }

    const updateDeviceAnalytics = async (userId, appInfo, userEmail) => {
        await analytics2(userId, appInfo, userEmail);
    };

    const tokenAuthenticate = async () => {
        const response = await authenticate(appInfo);
        if (response?.status === 200) {
            localStorage.setItem('selectedLanguageCode', response?.data?.user_language?.short_code);
            localStorage.setItem('selectedLanguageId', response?.data?.user_language?.language_id);
            dispatch(
                getLanguageModal({
                    languageModal: {
                        selectedLanguageCode: response?.data?.user_language?.short_code
                    }
                }))
            dispatch(
                getAccessToken({
                    accessToken: response?.data?.token,
                })
            );
            dispatch(
                getUserDetails({
                    userDetails: response?.data,
                })
            );
        }
    };

    const switchToAnotherAccount = () => {
        localStorage.clear();
        setActiveScreen('login');
        setEmail();
        setPassword();
        setMaskedPassword();
        dispatch(
            getUser({
                user: null,
            })
        );
        fetchSubscriptionList();
    }



    const manageErrorMessage = (isEmailValid, isPasswordValid) => {
        // console.log('isEmailValid, isPasswordValid', isEmailValid, isPasswordValid);

        const passwordMsg = isPasswordValid ? '' : 'Password required!';
        const emailMsg = isEmailValid ? '' : 'Email required!';
        setError({
            password: passwordMsg,
            email: emailMsg
        });
    }

    return (
        <div className="checkout-page">
            <div className="wrapper">
                {
                    emailLoginModal &&
                    <LoginModal isEmailForm={true} setEmailLoginModal={setEmailLoginModal} tvCode={tvCode} />
                }
                <div className="left">
                    <div>
                        <span className="heading">
                            {activeScreen == 'register' ? 'Create your account' : activeScreen == 'login-success' ? 'Account Confirmation' : 'Access your account'}
                        </span>
                        {activeScreen != 'login-success' && <div className="sign-in-link-section">
                            {activeScreen == 'register' ? <>
                                <span className="sub-heading">Already have account?</span>
                                <span className="signin-link" onClick={() => { setActiveScreen('login') }}>Sign In</span>
                            </>
                                : <>
                                    <span className="sub-heading">Create new account?</span>
                                    <span className="signin-link" onClick={() => { setActiveScreen('register') }}>Register</span>
                                </>}
                        </div>}

                    </div>
                    {activeScreen != 'login-success' ? <div className="user-section">

                        {projectInfo?.projectConfig?.config?.LOGIN_WITH_MAGIC_LINK_REQUIRED === "true" && activeScreen == 'login' &&
                            <> <div className="login-with-email-wrapper"
                                onClick={() => {
                                    localStorage.setItem('redirectFromNewCheckoutPage', 'true')
                                    setEmailLoginModal(true);
                                }}
                            >
                                <button >Sign In via Email</button>
                            </div>

                                <div className="seperate"><div className="line"></div><span className="devider">OR</span><div className="line"></div></div>
                                <div className="login-with-email-password">
                                    <span>Sign In via Email and Password</span>
                                </div>
                            </>
                        }
                        <div>
                            <input type="email" placeholder="Email"
                                onChange={(event) => {
                                    setEmail(event?.target?.value);
                                }}

                                onFocus={() => { setError({ ...error, ['email']: '' }) }}


                            />

                            {showAlreadyExistMessage && <div className="existing-user-message"><span className="close-message"
                                onClick={() => { setShowAlreadyExistMessage(false) }}>x</span><p>You already have an account
                                    associated with this email address.
                                    Please log in using your existing password.</p></div>}

                            {error?.email && <span className="error">{error.email}</span>}
                        </div>
                        <div className="password-section">
                            {isFocused || showPassword ? <>{password && <span className="password-show"
                                onClick={() => { setShowPassword(false) }}
                            ><svg width={20} height={20} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M2 2L22 22" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M6.71277 6.7226C3.66479 8.79527 2 12 2 12C2 12 5.63636 19 12 19C14.0503 19 15.8174 18.2734 17.2711 17.2884M11 5.05822C11.3254 5.02013 11.6588 5 12 5C18.3636 5 22 12 22 12C22 12 21.3082 13.3317 20 14.8335" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M14 14.2362C13.4692 14.7112 12.7684 15.0001 12 15.0001C10.3431 15.0001 9 13.657 9 12.0001C9 11.1764 9.33193 10.4303 9.86932 9.88818" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg></span>
                            }</> : <>{password && <span className="password-show"
                                onClick={() => { setShowPassword(true) }}
                            ><svg width={20} height={20} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M1 12C1 12 5 4 12 4C19 4 23 12 23 12" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M1 12C1 12 5 20 12 20C19 20 23 12 23 12" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> <circle cx="12" cy="12" r="3" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></circle> </g></svg></span>}</>}

                            <input type='text'
                                placeholder={activeScreen == 'register' ? 'Create Password' : 'Password'}
                                onFocus={() => {
                                    handleEmailValidation();
                                    setIsFocused(true); // Mark as focused to show the actual password while editing
                                }}
                                value={isFocused || showPassword ? password : maskedPassword}
                                onChange={(event) => {
                                    setPassword(event?.target?.value);
                                }}
                                onBlur={() => setIsFocused(false)}
                            />
                            {error?.password && <span className="error">{error.password}</span>}
                            {resetPasswordMessage && activeScreen == 'login' &&
                                <div>
                                    <p className="login-success-message error">{resetPasswordMessage}</p>
                                </div>}

                            {!resetPasswordMessage && activeScreen == 'login' && projectInfo?.projectConfig?.config?.CUSTOM_LOGIN_SCREEN_MESSAGE &&
                                <div>
                                    <p className="login-success-message error">{projectInfo?.projectConfig?.config?.CUSTOM_LOGIN_SCREEN_MESSAGE}</p>
                                </div>}


                        </div>
                        {activeScreen == 'login' && <div className="forgot-link">
                            <Link to={'/forgot-password'}><div className="sub-heading link forgot-text">Forgot password?</div></Link>
                            <button className="login-button" onClick={() => { handleLoginSubmit() }}>Sign In</button>
                        </div>}
                    </div> : <div className="user-section">
                        {userData && <p className="login-success-message">You're logged in as <span className="link">{userData?.user_email}</span>. Please select your preferred plan and click Continue to proceed.</p>}
                        <>
                            <span className="sub-heading">Switch account?</span>
                            <span className="link" onClick={() => { switchToAnotherAccount() }}> Sign In</span>
                        </>
                    </div>}
                </div>
                <div className="right">
                    <div>
                        <span className="heading">
                            Select your package
                        </span>
                    </div>
                    <div className="package-section">

                        {
                            subscriptions.length > 0 ? subscriptions.map((item, index) => {
                                return <div className="package-item">
                                    <div className="heading-section">
                                        <div className="heading-wrapper">
                                            <span className="heading">{item?.subscription_name}</span>
                                            <span className="sub-heading">{item?.subscription_text}</span>
                                        </div>
                                        <div className="plan-options-wrapper">
                                            <input type="radio" id={`selected-plan-${index}`} name="selected-plan" checked={selectedPlan == item?.subscription_id ? true : false} onChange={() => { setSelectedPlan(item?.subscription_id); setSelectedPlanPrice(item?.price) }} className="selected-plan plan-options" />
                                        </div>
                                    </div>
                                    {activeDetailsPlan == item?.subscription_id && <div className="plan-description">{item?.description}</div>}
                                    <div className="details" onClick={() => { activeDetailsPlan ? setActiveDetailsPlan() : setActiveDetailsPlan(item?.subscription_id) }}>
                                        {activeDetailsPlan == item?.subscription_id ? 'See less' : 'See details'}
                                    </div>
                                </div>
                            }) :
                                <div className="package-item">
                                    <div className="heading-section">
                                        <div className="heading-wrapper">
                                            <span className="sub-heading">Subscriptions fetching...</span>
                                        </div>
                                    </div>
                                </div>
                        }

                        <div>
                            <p className="disclaimer-text">
                                By creating an account, you agree to 4biddenknowledge's &nbsp;
                                <span className="link" onClick={() => { linkHandler('/terms-and-conditions') }}>Terms of Use</span> and &nbsp;
                                <span className="link" onClick={() => { linkHandler('/privacy-policy') }}>Privacy Policy</span> and
                                understand that 4biddenknowledge may send you marketing emails. You can unsubscribe from these communications at any time.
                            </p>
                        </div>

                        <div>
                            {isOkToProceed && <button className="continue-button" onClick={() => { handleSubmit() }}>Continue</button>}
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Checkout;